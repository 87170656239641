import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
   /* img {
    max-width: 100%;
  } */
body{
  background: rgb(2,0,36);
background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(255,255,255,1) 0%, rgba(211,223,255,1) 100%); 

}



.white{
  background-color: #fff;
  transition: all 0.3s linear;
  height: 100vh;
 
}

img{
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
}

figure{
  margin: 0;
  padding-top: 1rem;
  padding: 0;
  margin-top: 1.5rem;
}

footer {
  margin-top: 15vw;
  margin-bottom: 5vw;
}

p a {
  text-decoration: underline;
  text-decoration-color: #2c5ee8;
  :hover {
    color: #2c5ee8;
  }
}

.biglink{
  text-decoration: underline;
  text-decoration-color: #2c5ee8;
  :hover {
    color: #2c5ee8;
  }
}

.active{
  color: red;
}

`;

export default GlobalStyles;
