import { createGlobalStyle } from 'styled-components';

const Typography = createGlobalStyle`

.serif {
  font-family: 'Libre Baskerville', serif;
}

.sans{
  font-family: 'Libre Franklin', sans-serif;
}

h1,h2,h3,h4,h5,h6,p{
  font-family: 'Libre Franklin', sans-serif;
}

.subhead{
font-size: clamp(1.3rem, 3vw, 2rem); 
}

.big{
  font-size: clamp(1rem, 10vw, 168px); 
  line-height: 92%;
  padding-bottom: 1rem;
  letter-spacing: -10%;
  
}

.medium{
  font-size: clamp(.6rem, 6vw, 96px); 
  line-height: 92%;
  padding-bottom: 1rem;
  letter-spacing: -10%;  
}

.whitetext{
  color: #ffffff;
}

.caseName{
  font-weight: 700;
  font-size:2.5rem;
  text-decoration: none;
  margin-top: 0;
  padding-top: -10;
}


.heavy {
  font-weight: 700;
}
.light {
  font-weight: 100;
}

.none{
  text-decoration: none;
}
.eyebrow{
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: .5rem;
  padding: 0;
  text-transform: uppercase;
}



  html {
    font-family: 'Libre Franklin', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--black);
    font-size: 16px;
    overflow-x: hidden; //horizontal
  }
  p, li {
    /* letter-spacing: 0.5px; */
    font-size: 1.2rem;
    line-height: 1.7rem;
    margin-top: 0;
    margin-bottom: .15rem;
  }



  h1,h2,h3,h4,h5,h6 {
    font-weight: normal;
    margin: 0;
  }

  h2{
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: .25rem;
    margin-top: .25rem;
  }


  h3{
    font-size: 1.2rem;
    font-weight: 700;
  }

  .description{
    font-size: 1.5rem;
  }

  a {
    color: var(--black);
    text-decoration-color: var(--red);
    /* Chrome renders this weird with this font, so we turn it off */
    text-decoration-skip-ink: none;
  }
  mark, .mark {
    background: var(--yellow);
    padding: 0 2px 2px 2px;
    margin: 0;
    display: inline;
    line-height: 1;
  }

  .center {
    text-align: center;
  }

`;

export default Typography;
