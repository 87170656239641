import React from 'react';
import Layout from './src/components/Layout';

export const shouldUpdateScroll = (props) => {
  console.log(props);
  const scrollState = props.routerProps.location.state
    ? props.routerProps.location.state
    : 0;
  const { disableScrollUpdate } = scrollState;
  return !disableScrollUpdate;
};

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}

export const onRouteUpdate = (loc) => {
  const { state } = loc.location;
  if (state && state.refocusId) {
    const elem = document.getElementById(state.refocusId);
    if (elem) {
      elem.focus();
    }
  }
};
