import React from 'react';

export default function Footer() {
  return (
    <footer>
      <p style={{ fontSize: '.9em' }}>
        <span className="heavy">Find me: </span>
        {/* <a href="https://twitter.com/j_m_barnes" target="blank">
          Twitter
        </a>{' '}
        |{' '} */}
        <a
          href="https://www.linkedin.com/in/jamesmerrittbarnes/"
          target="blank"
        >
          LinkedIn
        </a>{' '}
        |{' '}
        <a href="https://github.com/jamesbarnes" target="blank">
          Github
        </a>
      </p>
      <h5>
        &copy; James Barnes {new Date().getFullYear()}. Made with Gatsby,
        Netlify, Sanity, and ♥.{' '}
      </h5>
    </footer>
  );
}
