import React from 'react';
import styled from 'styled-components';
import 'normalize.css';
import { CurlyQ } from '@rafaelrinaldi/curlyq';
import Navbar from './Navbar';
import Footer from './Footer';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';
import stripes from '../assets/images/stripes.svg';

const Container = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
`;

const SiteBorderStyles = styled.div`
  max-width: 1680px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  /* justify-content: center; */
  /* margin: 12rem auto 1rem auto; */
  /* margin-top: clamp(2rem, 10vw, 12rem); */
  /* border: 5px solid white; */
  /* @media (max-width: 1100px) {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  } */
`;

const ContentStyles = styled.div`
  /* background: white; */
  /* padding: 2rem; */
  width: 100%;
  align-self: center;
`;

export default function Layout({ children }) {
  return (
    <>
      <GlobalStyles />

      <Typography />
      <Container>
        <CurlyQ>
          <SiteBorderStyles>
            <ContentStyles>
              <Navbar />
              {children}
              <Footer />
            </ContentStyles>
          </SiteBorderStyles>
        </CurlyQ>
      </Container>
    </>
  );
}
