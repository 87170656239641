import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

// .navItem{
//     font-family: 'Libre Franklin', sans-serif;
//     font-weight: 700;
//     font-size: 1.5.rem;
//     text-decoration: none;
//     float: left;
//     :hover{
//       text-decoration: underline;
//     }
//   }

const NavItem = styled(Link)`
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 700;
  font-size: 1.5.rem;
  text-decoration: none;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 100ms ease-in;
  position: relative;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: '.';
    color: transparent;
    background: #f76440;
    height: 1px;
    transition: all 0.2s ease-in;
  }

  :hover {
    color: #f76440;
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 1.5rem;
    z-index: 6;
  }
`;

export const NavbarLinks = () => (
  <>
    <NavItem to="/">Home</NavItem>
    <NavItem to="/work/">Work</NavItem>
    <NavItem to="/">Contact</NavItem>
  </>
);

export const NavbarCloseLinks = () => (
  <>
    <NavItem onClick={() => setNavbarOpen(!navbarOpen)} to="/">
      Home
    </NavItem>
    <NavItem onClick={() => setNavbarOpen(!navbarOpen)} to="/work/">
      Work
    </NavItem>
    <NavItem onClick={() => setNavbarOpen(!navbarOpen)} to="/">
      Contact
    </NavItem>
  </>
);
