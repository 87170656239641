import { Link } from 'gatsby';
import React, { useState } from 'react';
import styled from 'styled-components';
import NavbarLinks from './NavbarLinks';

const Navigation = styled.nav`
  /* background-color: white; */
  height: 8vh;
  display: flex;
  position: relative;
  justify-content: flex-end;
  text-transform: uppercase;
  /* border-bottom: 2px solid #33333320; */
  margin: 0 auto;
  padding: 0 5vw;
  z-index: 2;
  align-self: center;

  @media (max-width: 768px) {
    transition: all 0.3s linear;

    position: sticky;
    height: 8vh;
    top: 0;
    left: 0;
    right: 0;
    left: 0;

    ::before,
    ::after {
      background-color: #fff;

      transition: all 0.3s linear;
    }
  }
`;

const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  padding: 0 10vw;

  @media (max-width: 768px) {
    /* background-color: grey; */
    display: flex;
    padding: 0px;
  }
`;

const Navbox = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    padding-top: 10vh;
    background-color: #fff;
    margin-top: 10px;
    transition: all 0.3s ease-in;
    top: 8vh;
    left: ${(props) => (props.open ? '-100%' : '0')};
  }
`;

const Hamburger = styled.div`
  background-color: #111;
  width: 30px;
  height: 3px;
  transition: all 0.3s linear;
  /* align-self: center; */
  position: relative;
  margin-top: 2rem;
  transform: ${(props) => (props.open ? 'rotate(-45deg)' : 'inherit')};

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: #111;
    content: '';
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${(props) =>
      props.open ? 'rotate(-90deg) translate(-10px, 0px)' : 'rotate(0deg)'};
    top: -10px;
  }

  ::after {
    opacity: ${(props) => (props.open ? '0' : '1')};
    transform: ${(props) => (props.open ? 'rotate(90deg) ' : 'rotate(0deg)')};
    top: 10px;
  }
`;

const NavItem = styled(Link)`
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 700;
  font-size: 1.5.rem;
  text-decoration: none;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 100ms ease-in;
  position: relative;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: '.';
    color: transparent;
    background: #2c5ee8;
    height: 2px;
    transition: all 0.2s ease-in;
  }

  :hover {
    color: #2c5ee8;
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 1.5rem;
    z-index: 6;
  }
`;

const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <Navigation className={navbarOpen ? 'white' : ''}>
      <Toggle
        navbarOpen={navbarOpen}
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        {navbarOpen ? <Hamburger open /> : <Hamburger />}
      </Toggle>
      {navbarOpen ? (
        <Navbox>
          <NavItem onClick={() => setNavbarOpen(!navbarOpen)} to="/">
            Home
          </NavItem>
          <NavItem onClick={() => setNavbarOpen(!navbarOpen)} to="/work/">
            Work
          </NavItem>
          {/* <NavItem onClick={() => setNavbarOpen(!navbarOpen)} to="/contact">
            Contact
          </NavItem> */}
        </Navbox>
      ) : (
        <Navbox open>
          <NavItem to="/">Home</NavItem>
          <NavItem to="/work/">Work</NavItem>
          {/* <NavItem to="/contact">Contact</NavItem> */}
        </Navbox>
      )}
    </Navigation>
  );
};

export default Navbar;
